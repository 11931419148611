import { Spinner } from "spin.js";

import { initializeSentry } from "../../utils/sentry";

export interface IWebtruSpinner {
  __baseZIndex: number;
  __counter: number;
  __spinner: Spinner;
  __getBgElement: () => HTMLElement;
  __spinnerBgId: string;
  start: () => void;
  stop: () => void;
}

declare global {
  interface Window {
    webtruSpinner: IWebtruSpinner;
  }
}
class WebtruSpinner implements IWebtruSpinner {
  __baseZIndex = 2000000000;
  __spinner = new Spinner({ zIndex: this.__baseZIndex, scale: 2.0 });
  __counter = 0;
  __spinnerBgId = "__webtru_spinner_area__";
  __getBgElement = (): HTMLElement => {
    const bg = document.createElement("div");
    bg.id = this.__spinnerBgId;
    Object.assign(bg.style, {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: this.__baseZIndex - 1,
    });

    // 本来はspin.js/spin.cssをimportして適用したかったがそこまで頑張る必要もないと判断してこうした
    const styleTag = document.createElement("style");
    styleTag.textContent = `
  @keyframes spinner-line-fade-more {
  0%, 100% {
    opacity: 0; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-quick {
  0%, 39%, 100% {
    opacity: 0.25; /* minimum opacity */
  }
  40% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-default {
  0%, 100% {
    opacity: 0.22; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-shrink {
  0%, 25%, 100% {
    /* minimum scale and opacity */
    transform: scale(0.5);
    opacity: 0.25;
  }
  26% {
    transform: scale(1);
    opacity: 1;
  }
}
`;
    bg.appendChild(styleTag);

    return bg;
  };
  start = (): void => {
    this.__counter++;
    const elem = document.getElementById(this.__spinnerBgId);
    if (!elem) {
      // this.__spinner.spin();
      document.body.appendChild(this.__getBgElement());
      // const spinner = new Spinner().spin();
      this.__spinner.spin(document.body);
    }
  };
  stop = (): void => {
    if (this.__counter !== 0) {
      // this.__counterが0の状態でデクリメントされると困るのでこうしている
      this.__counter--;
    }

    if (this.__counter === 0) {
      // startせずにstopされるとエラーになるが、consoleにエラーが出るだけなので無視
      this.__spinner.stop();
      document.getElementById(this.__spinnerBgId).remove();
    }
  };
}

export function main(): void {
  window.webtruSpinner = new WebtruSpinner();
}

initializeSentry();
main();
